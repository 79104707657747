import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridEventListener } from '@mui/x-data-grid';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';



import Grid from "./Grid";
import GridHeader from "./GridHeader";

import Details from "./Details";
import axios from "axios";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebaseAuth";
import {
    useNavigate,
    // Navigate
} from "react-router-dom";


var token=""

const FDataGrid = () => {
  const [status, setStatus] = React.useState(0);
  const [id, setId] = React.useState(0);
  const [item_data, setItemData] = React.useState([]);
 const [user, setUser] = useState([]);
 const [img, setImg] = useState("");


    useEffect(() => {
       
     


    }, []);

    const selectHeader= (id) => {
      setId(id)
      setStatus(1)
    };
  return (
    <div style={{ height: window.innerHeight -100, width: '100%' }}>
    注文管理

  
    <GridHeader  pageSize={100} selectHeader={selectHeader}/>:
{/* <Grid  pageSize={100} setStatus={setStatus} id={id}/> */}


    </div>
  );
}
export default FDataGrid

