// import Home from './components/Home';
// import SignUp from './components/SignUp';
// import Login from './components/Login';
import React from "react";
import { BrowserRouter, Route, Routes, Link, BrowserRouter as Router } from "react-router-dom";
import Register from './components/Register';
import Login from './components/Login';
import Mypage from './components/Mypage';
import Layout from './components/Layout';
import Research from './components/Research';
import WaitListings from './components/WaitListings/Index';
import ScrapingListings from './components/ScrapingListings/Index';
import OrderListings from './components/OrderListings/Index';
import Calculations from './components/Calculations/Index';
import AutomationLogs from './components/AutomationLogs/Index';
import Messages from './components/Messages/Index';
const App = () => {
  return (
    <div className="container">
      <BrowserRouter>
        <Layout>


          <Routes>
            <Route path={`/register/`} element={<Register />} />
            <Route path={`/login/`} element={<Login />} />
            <Route path={`/research/`} element={<Research />} />
            <Route path={`/waitlistings/`} element={<WaitListings />} />
            <Route path={`/scrapinglistings/`} element={<ScrapingListings />} />
            <Route path={`/orderlistings/`} element={<OrderListings />} />
            <Route path={`/Calculations/`} element={<Calculations />} />
            <Route path={`/AutomationLogs/`} element={<AutomationLogs />} />
            <Route path={`/Messages/`} element={<Messages />} />
            <Route path={`/`} element={<Calculations />} />
          </Routes>


        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;