// import type { NextPage } from "next";
// import styles from "../styles/Home.module.css";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnResizeMode,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
// import { COLUMNS, DATA } from "./data";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useMemo } from "react";
import { onAuthStateChanged } from "firebase/auth";
import "./table.css";
import { auth } from "../../firebaseAuth";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
type Person = {
  remarks: string;
  url: string;
  id: number;
  created_at: any;
  start_at: any;
  end_at: any;
  completion_at: any;
  list_count: number;
  unchecked_count: number;
};

const columnHelper = createColumnHelper<Person>();

const Home = (props) => {
  // const columns = useMemo(() => COLUMNS, []);

  // const [data, setData] = React.useState(() => [...d.data])
  const [data, setData] = useState([]);
  const [token, setToken] = useState("");
  const [user, setUser] = useState([]);
  const [item_data, setItemData] = React.useState([]);
  const [back_drop_open, setBackDropOpen] = useState(false);
  const [image_url, set_image_url] = useState("");

  const [open, setOpen] = useState(false);

  // const data = useMemo(() => d.data, []);
  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>("onChange");

  useEffect(() => {
    setToken("sssss");
    onAuthStateChanged(auth, async (currentUser) => {
      console.log(currentUser);
      if (currentUser) {
        var tokeset = "";
        tokeset = await currentUser.getIdToken();
        // alert(tokeset)

        // alert(token)
        // setUser(currentUser);
        rest(tokeset);
      }
    });
  }, []);

  const rest = async (tokeset) => {
    // alert()
    setBackDropOpen(true);
    let headers = {
      Authorization: `Bearer ${tokeset}`,
    };

    // alert(datas)
    // console.log('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/stocks/save')
    const items: any = await axios
      .post(
        "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/16",
        {},
        { headers: headers }
      )
      .catch(function (error) {
        alert(error);
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    // alert("aiteme")
    // alert(JSON.stringify(items))

    setData(items.data);
    setBackDropOpen(false);
  };

  const delete_item = async (item_id) => {
    // alert()
    // const [item_data, setItemData] = React.useState([]);
    alert(item_id);
    let item_data_set: any = item_data.filter((a: any) => a.id == item_id);

    setItemData(item_data_set);
  };

  const handleClose = (url) => {
    set_image_url(url);
    setOpen(!open);
  };

  const link = (url) => {
    window.open(url, "_blank");
    // onClick="window.open('"+cell.row.original.url+"','_blank')"
    // alert()
  };

  const columns = [
    // columnHelper.accessor('id', {
    //   cell: info => <><IconButton aria-label="delete">
    //       <CloseIcon onClick={() => delete_item(info.renderValue())} style={{fontWeight:"bold"}}/>
    //     </IconButton></>,
    //   footer: info => info.column.id,
    // }),

    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => <span>ID</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.url, {
      id: "url",
      cell: (info) => (
        <IconButton color="primary" onClick={() => link(info.renderValue())}>
          <ShoppingCartIcon />
        </IconButton>
      ),
      header: () => (
        <span>
          <Button>aaa</Button>
        </span>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.remarks, {
      id: "name",
      header: () => <span>備考</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => (
        <Button
          variant="contained"
          onClick={() => props.selectHeader(info.renderValue())}
        >
          編集
        </Button>
      ),
      header: () => (
        <span>
          <button>aaa</button>
        </span>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.created_at, {
      id: "created_at",
      header: () => <span>作成日</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.completion_at, {
      id: "completion_at",
      header: () => <span>完了日</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.list_count, {
      id: "list_count",
      header: () => <span>アイテム数</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.unchecked_count, {
      id: "unchecked_count",
      header: () => <span>未チェックアイテム数</span>,
      cell: (info) => <>{info.renderValue()}</>,
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode,
    initialState: {
      pagination: {
        pageSize: 100,
      },
    },
  });

  return (
    <div>
      <main>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} onClick={() => console.log(cell)}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "5px" }}>
          <span>Page</span>

          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </div>
        <div>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Age</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={table.getState().pagination.pageSize}
            label="Age"
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 100, 50].map((pageSize) => (
              <MenuItem value={pageSize}>{pageSize}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>{table.getRowModel().rows.length} Rows</div>
      </main>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <img src={image_url} alt="" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={back_drop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
