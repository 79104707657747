import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
} from "@mui/x-data-grid";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import axios from "axios";
import Alert from "@mui/material/Alert";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebaseAuth";
import {
  useNavigate,
  // Navigate
} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
var token = "";

const FDataGrid = () => {
  const [status, setStatus] = React.useState(0);
  const [shipping_cost, set_shipping_cost] = React.useState([
    { from_buying_price: 0, to_buying_price: 0, receipt_shipping_cost: 0 },
  ]);
  const [calc, setCalc] = React.useState({
    ebay_finalvalue: 0,
    payoneer: 0,
    promoted_listing: 0,
    ebay_transaction_fee: 0,
    exchange_rate: 0,
  });
  const [user, setUser] = useState([]);
  const [buying_price, set_buying_price] = useState(0);
  const [token, setToken] = useState("");
  useEffect(() => {
    setToken("sssss");
    onAuthStateChanged(auth, async (currentUser) => {
      console.log(currentUser);
      if (currentUser) {
        var tokeset = "";
        tokeset = await currentUser.getIdToken();
        // alert(tokeset)
        setToken(tokeset);
        // alert(tokeset)
        // setUser(currentUser);
        rest(tokeset, 24);
      }
    });
  }, []);

  const rest = async (tokeset, nom) => {
    // alert()
    // alert(token)
    let headers = {
      Authorization: `Bearer ${tokeset}`,
    };

    // alert(datas)
    // console.log('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/stocks/save')
    const items: any = await axios
      .post(
        "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/24",
        {},
        { headers: headers }
      )
      .catch(function (error) {
        alert(error);
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    setCalc(items.data);

    const shipping_cost: any = await axios
      .post(
        "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/25",
        {},
        { headers: headers }
      )
      .catch(function (error) {
        alert(error);
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    set_shipping_cost(shipping_cost.data);
    // alert("aiteme")
    // alert(JSON.stringify(shipping_cost.data));
  };

  const selectHeader = (id) => {
    setStatus(1);
  };
  return (
    <div style={{ height: window.innerHeight - 100, width: "100%" }}>
      利益計算
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="ebay基本手数料"
        variant="standard"
        value={calc.ebay_finalvalue}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">％</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="payoneer手数料"
        variant="standard"
        value={calc.payoneer}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">％</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="promoted listing手数料"
        variant="standard"
        value={calc.promoted_listing}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">％</InputAdornment>,
        }}
      />
      <TextField
        label="ebay_transaction_fee"
        sx={{ m: 1, width: "15ch" }}
        id="outlined-start-adornment"
        value={calc.ebay_transaction_fee}
        variant="standard"
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">％</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="1ドルを日本円で"
        variant="standard"
        value={calc.exchange_rate}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="販売価格"
        variant="standard"
        value={calc.exchange_rate}
        // inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="販売価格日本円換算"
        variant="standard"
        value={calc.exchange_rate}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="購入価格"
        variant="standard"
        value={buying_price}
        onChange={(e: any) => set_buying_price(Number(e.target.value))}
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />
      <TextField
        id="standard-basic"
        sx={{ m: 1, width: "15ch" }}
        label="送料見積"
        variant="standard"
        value={
          shipping_cost.filter(
            (a: any) =>
              a.from_buying_price <= Number(buying_price) &&
              a.to_buying_price >= Number(buying_price)
          )[0].receipt_shipping_cost
            ? shipping_cost.filter(
                (a: any) =>
                  a.from_buying_price <= Number(buying_price) &&
                  a.to_buying_price >= Number(buying_price)
              )[0].receipt_shipping_cost
            : 0
        }
        inputProps={{ min: 0, style: { textAlign: "right" } }}
        InputProps={{
          endAdornment: <InputAdornment position="end">円</InputAdornment>,
        }}
      />
      <Alert severity="error">
        手数料率合計{" "}
        {calc.ebay_finalvalue + calc.payoneer + calc.promoted_listing}%=
        {calc.ebay_finalvalue}%+ {calc.payoneer}% +{calc.promoted_listing}%
        {(calc.ebay_finalvalue + calc.payoneer + calc.promoted_listing) / 100}
      </Alert>
      <Alert severity="info">
        損益分岐点
        {Math.ceil(
          buying_price / calc.exchange_rate +
            (shipping_cost.filter(
              (a: any) =>
                a.from_buying_price <= Number(buying_price) &&
                a.to_buying_price >= Number(buying_price)
            )[0].receipt_shipping_cost
              ? shipping_cost.filter(
                  (a: any) =>
                    a.from_buying_price <= Number(buying_price) &&
                    a.to_buying_price >= Number(buying_price)
                )[0].receipt_shipping_cost
              : 0) /
              calc.exchange_rate +
            0.3
        ) /
          (1 -
            (calc.ebay_finalvalue + calc.payoneer + calc.promoted_listing) /
              100)}
      </Alert>
      <Alert severity="info">
        20%
        {Math.ceil(
          buying_price / calc.exchange_rate +
            (shipping_cost.filter(
              (a: any) =>
                a.from_buying_price <= Number(buying_price) &&
                a.to_buying_price >= Number(buying_price)
            )[0].receipt_shipping_cost
              ? shipping_cost.filter(
                  (a: any) =>
                    a.from_buying_price <= Number(buying_price) &&
                    a.to_buying_price >= Number(buying_price)
                )[0].receipt_shipping_cost
              : 0) /
              calc.exchange_rate +
            0.3
        ) /
          (1 -
            0.2 -
            (calc.ebay_finalvalue + calc.payoneer + calc.promoted_listing) /
              100)}
      </Alert>
      price_us=math.ceil((float(buying_price)+ebay_shipping_cost_masters.receipt_shipping_cost/calc.exchange_rate+0.3)/(1-profit-fee))
    </div>
  );
};
export default FDataGrid;
