// import type { NextPage } from "next";
// import styles from "../styles/Home.module.css";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnResizeMode,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
// import { COLUMNS, DATA } from "./data";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useMemo } from "react";
import { onAuthStateChanged } from "firebase/auth";
import "./table.css";
import { auth } from "../../firebaseAuth";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";

type ScrapingList = {
  name: string;
  url: string;
  id: number;
  name_us: string;
  brand: string;
  brand_us: string;
  price_us: number;
  price: number;
  price_adjustment: number;
  images: string;
  deleted_at: any;
  status: number;
  progress: number;
  checked_at: any;
  checked: boolean;
  best_offer_auto_accept_price: any;
  minimum_best_offer_price: any;
  estimated_shipping_cost: any;
  discription: any;
  alert_words: string;
  delete_reason_id: number;
  m_condition_flg_ids: string;
  
};
// status =1 更新あり

const columnHelper = createColumnHelper<ScrapingList>();

const Home = (props) => {
  // const columns = useMemo(() => COLUMNS, []);

  // const [data, setData] = React.useState(() => [...d.data])
  const [data, setData] = useState<ScrapingList[]>([]);
  const [alldata, setAllData] = useState<ScrapingList[]>([]);
  const [count, setCount] = useState(0);
  const [token, setToken] = useState("");
  const [over_text_flg, set_over_text_flg] = useState(false);
  const [user, setUser] = useState([]);
  const [checked, setChecked] = React.useState(false);

  const [image_url, set_image_url] = useState("");

  const [back_drop_open, setBackDropOpen] = useState(false);
  const [page_index, set_page_index] = useState(0);

  const [open, setOpen] = useState(false);
  const [delete_reasons, setDeleteReasons] = useState([]);

  // const data = useMemo(() => d.data, []);
  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>("onChange");

  useEffect(() => {
    setToken("sssss");
    onAuthStateChanged(auth, async (currentUser) => {
      console.log(currentUser);
      if (currentUser) {
        var tokeset = "";
        tokeset = await currentUser.getIdToken();
        // alert(tokeset)
        setToken(tokeset);
        // alert(tokeset)
        // setUser(currentUser);
        rest(tokeset);
      }
    });
  }, []); //初回のみ実行

  // 初回レンダリング後と、useした値の更新後に自動で実行
  //  useEffect(() => {
  //   // レンダリング後なので、xは更新後の値
  //   console.log(page_index)
  //   table.setPageIndex(page_index)
  // });

  const rest = async (tokeset) => {
    setBackDropOpen(true);
    // alert()
    // alert(token)
    let headers = {
      Authorization: `Bearer ${tokeset}`,
    };
    // alert(datas)
    // console.log('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/stocks/save')
    // alert(props.id);
    const items: any = await axios
      .post(
        "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/14",
        { data: JSON.stringify({ id: props.id }) },
        { headers: headers }
      )
      .catch(function (error) {
        // alert(error)
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    console.log("デリーと");
    const delete_reasons: any = await axios
      .post(
        "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/23",
        { data: JSON.stringify({}) },
        { headers: headers }
      )
      .catch(function (error) {
        alert(error);
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    // alert(items.data.length);
    // alert(JSON.stringify(items))
    let data_set: any = items.data.list.filter(
      (a: any) =>
        // a.deleted_at == null &&
        a.block_seller == null &&
        a.block_word == null &&
        a.block_vero == null &&
        // a.image_ocr == null &&
        a.ended_at == null
    );
    // alert(data_set.length);
    setData(data_set.filter((a) => a.checked_at == null));

    setAllData(data_set);
    setCount(items.data.count);
    // console.log(delete_reasons);
    // alert(JSON.parse(delete_reasons));
    setDeleteReasons(delete_reasons.data);

    setBackDropOpen(false);
  };

  const save = async () => {
    // alert(token)
    setBackDropOpen(true);
    // alert()
    // alert(token)
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    var url =
      "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/15";
    // if (checked) {
    //   url =
    //     "https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/items/17";
    // }
    // alert(datas)
    // console.log('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/stocks/save')
    const items: any = await axios
      .post(url, { data: JSON.stringify(alldata) }, { headers: headers })
      .catch(function (error) {
        // alert(error)
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました

          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    // alert("aiteme")
    // alert(JSON.stringify(items))

    // setData(items.data)
    let data_set: any = items.data.list.filter(
      (a: any) =>
        // a.deleted_at == null &&
        a.block_seller == null &&
        a.block_word == null &&
        a.block_vero == null &&
        // a.image_ocr == null &&
        a.ended_at == null
    );
    // alert(data_set.length);
    setData(data_set.filter((a) => a.checked_at == null));

    setAllData(data_set);
    setCount(items.data.count);
    setBackDropOpen(false);
  };

  const allchecked = (checked) => {
    // alert(checked);
    setBackDropOpen(true);
    var data_checked = data.map(function (value, key) {
      value.checked = checked;
      value.status = 1;
      return value;
    });
    setData(data_checked);

    var alldata_checked = alldata.map(function (value, key) {
      if (data.filter((a) => a.id == value.id)) {
        value.checked = checked;
        value.status = 1;
      }
      return value;
    });
    setAllData(alldata_checked);

    setBackDropOpen(false);
  };

  const pagechecked = (checked) => {
    setBackDropOpen(true);
    console.log(table.getState().pagination.pageIndex * 100);
    var data_checked = [...data].map(function (value, index) {
      console.log(index);
      if (
        index >= table.getState().pagination.pageIndex * 100 &&
        index <= (table.getState().pagination.pageIndex + 1) * 100 - 1
      ) {
        console.log(index);
        value.checked = checked;
        value.status = 1;
      }
      return value;
    });
    setData(data_checked);

    var alldata_checked = [...alldata].map(function (valueall, index) {
      if (
        data_checked.filter((a) => a.id == valueall.id && a.status == 1)
          .length > 0
      ) {
        valueall.checked = checked;
        valueall.status = 1;
      }
      return valueall;
    });
    // setAllData(alldata_checked);
    console.log(data);
    setBackDropOpen(false);
  };

  const delete_item = async (id: number, row: any) => {
    // setData([])
    // alert(item_id)
    // alert(row.original.id)
    let copy_alldata = [...alldata];
    if (id == 0) {
      copy_alldata.filter((a: any) => a.id == row.id)[0].deleted_at =
        null;
      copy_alldata.filter(
        (a: any) => a.id == row.id
      )[0].delete_reason_id = 0;
    } else {
      copy_alldata.filter((a: any) => a.id == row.id)[0].deleted_at =
        new Date();
      copy_alldata.filter(
        (a: any) => a.id == row.id
      )[0].delete_reason_id = id;
    }
    copy_alldata.filter((a: any) => a.id == row.id)[0].status = 1;

    //  let data_set:any = alldata.filter((a:any)=>a.deleted_at==null)
    // alert(data.length)
    setAllData(copy_alldata);

    // alert(data_set.length)

    // alert(table.getState().pagination.pageIndex)
    let copy_data = [...data];
    // alert(copy_data.length)
    // alert(row.index)
    // copy_data.splice(row.index, 1);
    // if(over_text_flg){
    //   data_set = alldata.filter((a:any)=>a.deleted_at==null && a.name_us && a.name_us.length>80)
    // }

    // alert(copy_data.length)
    // set_page_index(table.getState().pagination.pageIndex)
    setData(copy_data);
  };



  const over_text = async () => {
    set_over_text_flg(true);
    let data_set: any = alldata.filter(
      (a: any) => a.name_us && a.name_us.length > 80
    );
    // alert(data_set.length)

    setData(data_set);
  };

  const all_text = async () => {
    set_over_text_flg(false);
    let data_set: any = alldata;
    // alert(data_set.length)

    setData(data_set);
  };

  const handleClose = (url) => {
    table.setPageIndex(2);
    setOpen(!open);
  };

  const link = (url) => {
    window.open(url, "_blank");
    // onClick="window.open('"+cell.row.original.url+"','_blank')"
    // alert()
  };

  const handleChecked = () => {
    setChecked(!checked);
    var data_set: ScrapingList[];
    if (!checked) {
      data_set = alldata.filter((a) => a.checked_at != null);
    } else {
      data_set = alldata.filter((a) => a.checked_at == null);
    }
    setData(data_set);
  };

  const TableCell = (row: any) => {
    const [value, setValue] = useState("");

    const change_cell = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].name_us = e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].name_us = e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };

    return (
      <>
        <TextField
          id="standard-basic"
          onChange={(e) => change_cell(e)}
          style={{
            width: 700,
            background: row.name_us && row.name_us.length > 80 ? "pink" : "",
          }}
          label={"タイトル   文字数" + (row.name_us ? row.name_us.length : "")}
          error={row.name_us ? row.name_us.length > 80 : false}
          variant="standard"
          value={row.name_us}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 700 }}
          label="タイトル"
          variant="standard"
          value={row.name}
        />
        <br />
        {row.images
          ? row.images.split(",").map(function (value, key) {
              if (key > 0) {
                return (
                  <img
                    src={
                      "https://frogger-images.s3.ap-northeast-1.amazonaws.com/" +
                      row.id +
                      "/" +
                      row.id +
                      "-" +
                      (key + 1) +
                      ".jpg"
                    }
                    height="180"
                    style={{ margin: 5 }}
                    alt=""
                  />
                );
              }
            })
          : ""}
      </>
    );
  };



  
  const ImageCell = (row: any) => {
    // const [value, setValue] = useState("");


    const set_conditions= async (id: number, row: any) => {
      // setData([])
      // alert(item_id)
      // alert(row.original.id)
      // alert(id)
      let copy_alldata = [...alldata];
      var m_condition_flg_ids =[] as string[]
      if (id == 0) {
        var name_us=alldata.filter((a: any) => a.id == row.id)[0].name_us
        if(name_us.indexOf('No Box')!==-1){
          copy_alldata.filter((a: any) => a.id == row.id)[0].name_us = name_us.replace("No Box", "").replace("  ", " ").trim() ;
          }
        if(copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids && copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').includes('1')){
           m_condition_flg_ids = copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').filter(item => item !== '1');
  
         copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids =m_condition_flg_ids.join(',')
        }
        
   
       
      } else {
        // alert(copy_alldata.filter((a: any) => a.id == row.original.id)[0].name_us )
        var name_us=alldata.filter((a: any) => a.id == row.id)[0].name_us
        if(name_us.indexOf('No Box')===-1){
        copy_alldata.filter((a: any) => a.id == row.id)[0].name_us = name_us+" No Box";
        }
        if(copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids && copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').includes('1')){
        }else{
            m_condition_flg_ids = copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids?copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(','):[];
            m_condition_flg_ids.push('1')
          copy_alldata.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids =m_condition_flg_ids.join(',')
         }
      }
      copy_alldata.filter((a: any) => a.id == row.id)[0].status = 1;
  
      setAllData(copy_alldata);
  
      let copy_data = [...data];
      var m_condition_flg_ids =[] as string[]
      if (id == 0) {
        name_us=data.filter((a: any) => a.id == row.id)[0].name_us
        if(name_us.indexOf('No Box')!==-1){
          copy_data.filter((a: any) => a.id == row.id)[0].name_us = name_us.replace("No Box", "").replace("  ", " ").trim() ;
          }
        if(copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids && copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').includes('1')){
           m_condition_flg_ids = copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').filter(item => item !== '1');
  
           copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids =m_condition_flg_ids.join(',')
        }
        
   
       
      } else {
        if(copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids && copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',').includes('1')){
        }else{
          if(data.filter((a: any) => a.id == row.id)[0].name_us.indexOf('No Box')===-1){
          copy_data.filter((a: any) => a.id == row.id)[0].name_us = data.filter((a: any) => a.id == row.id)[0].name_us+" No Box";
          }
            m_condition_flg_ids = copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids.split(',');
            m_condition_flg_ids.push('1')
            copy_data.filter((a: any) => a.id == row.id)[0].m_condition_flg_ids =m_condition_flg_ids.join(',')
         }
      }
      setData(copy_data);
    };

    return (
      <>
        <img
          src={
            "https://frogger-images.s3.ap-northeast-1.amazonaws.com/" +
            row.id +
            "/" +
            row.id +
            "-1.jpg"
          }
          height="180"
          // onClick={()=>handleClose("https://frogger-images.s3.ap-northeast-1.amazonaws.com/"+info.renderValue()+"/"+info.renderValue()+"-1.jpg")}
          alt=""/>
         {(row.m_condition_flg_ids && row.m_condition_flg_ids.split(",").includes('1'))?<Button variant="contained" onClick={() => set_conditions(0, row)} style={{margin:5}} color="error">箱なし</Button>:<Button variant="contained" onClick={() => set_conditions(1, row)} style={{margin:5}}>箱あり</Button>}
      </>
    );
  };



  const TableBrand = (row: any) => {
    const [value, setValue] = useState("");

    const change_cell = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].brand_us = e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].brand_us = e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };

    const change_price_us = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].price_us = e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].price_us = e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };

    const change_price_adjustment = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].price_adjustment = e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].price_adjustment = e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };
    

    return (
      <>
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="仕入金額(ドル)"
          variant="standard"
          value={row.buying_price_us}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="仕入金額(円)"
          variant="standard"
          value={row.buying_price}
        />
        <br />

        
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="調整金額(円)"
          variant="standard"
          onChange={(e) => change_price_adjustment(e)}
          value={row.price_adjustment}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="販売額(ドル)"
          variant="standard"
          onChange={(e) => change_price_us(e)}
          value={row.price_us}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="ブランド"
          variant="standard"
          onChange={(e) => change_cell(e)}
          value={row.brand_us}
        />
      </>
    );
  };

  const TablePrice = (row: any) => {
    const [value, setValue] = useState("");

    const change_estimated_shipping_cost = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].estimated_shipping_cost =
        e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].estimated_shipping_cost =
        e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };
    const change_best_offer_auto_accept_price = (e: any) => {
      alldata.filter(
        (a: any) => a.id == row.id
      )[0].best_offer_auto_accept_price = e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].best_offer_auto_accept_price =
        e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };
    const change_minimum_best_offer_price = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].minimum_best_offer_price =
        e.target.value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].minimum_best_offer_price =
        e.target.value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);

      setValue(e.target.value);
    };
    return (
      <>
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="自動ベストオファー受入"
          variant="standard"
          onChange={(e) => change_best_offer_auto_accept_price(e)}
          value={row.best_offer_auto_accept_price}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="最低ベストオファー"
          variant="standard"
          onChange={(e) => change_minimum_best_offer_price(e)}
          value={row.minimum_best_offer_price}
        />
        <br />

        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="見積配送料"
          variant="standard"
          onChange={(e) => change_estimated_shipping_cost(e)}
          value={row.estimated_shipping_cost}
        />
        <br />
        <TextField
          id="standard-basic"
          style={{ width: 100 }}
          label="損益分岐点"
          variant="standard"
          disabled={true}
          value={row.break_even_price_us}
        />
      </>
    );
  };

  const TableCheck = (row: any) => {
    const [value, setValue] = useState(row.checked);

    const change_cell = (e: any) => {
      alldata.filter((a: any) => a.id == row.id)[0].checked = !value;

      alldata.filter((a: any) => a.id == row.id)[0].status = 1;

      setAllData(alldata);

      data.filter((a: any) => a.id == row.id)[0].checked = !value;

      data.filter((a: any) => a.id == row.id)[0].status = 1;

      setData(data);
      // alert(e.target.value);
      setValue(!value);
    };

    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={row.checked}
              value={value}
              onChange={(e) => change_cell(e)}
            />
          }
          label="チェック済にする"
        />
        <br />
        {row.checked_at}
        <br />
        ebayid{row.ebay_legacy_item_id}
        <br />
        ヘッダーID{row.scraping_list_header_id}
      </>
    );
  };

  const keywords = ['おまけ', '紛失', '欠品', 'リペイント', '箱なし', '箱無'];

const TextArea = (row:any) => {




  // 正規表現を使用して単語をハイライトする関数
  const highlightText = (text:any, words:any) => {
    const pattern = new RegExp(`(${words.join('|')})`, 'g');
    return text.split(pattern).map((word:any, index:number) => {
      if (words.includes(word)) {
        return <span key={index} style={{ backgroundColor: 'yellow' }}>{word}</span>;
      }
      return word;
    });
  };

  return (
    <div style={{height: "200px",overflow: "auto"}}>
      {highlightText(row.discription, keywords)}
    </div>
  );
};

const SelectorCell = (row:any) => {





const matchingElements = keywords.filter(keyword => row.discription.includes(keyword));

  return (
    <div style={{ width: 100 }}>
          {/* <IconButton aria-label="delete">
            <CloseIcon
              onClick={() => delete_item(0, info.row)}
              style={{ fontWeight: "bold" }}
            />
          </IconButton> */}
          <IconButton
            color="primary"
            onClick={() => link(row.url)}
          >
            <ShoppingCartIcon />
          </IconButton>
          {/* {delete_reasons.length} */}
          {delete_reasons.map(function (value: any) {
            return (
              <Button
                onClick={() => delete_item(value.id, row)}
                color={
                  row.delete_reason_id == value.id
                    ? "error"
                    : "primary"
                }
              >
                {value.name}
              </Button>
            );
          })}

          {row.deleted_at ? (
            <Button onClick={() => delete_item(0, row)}>削除取消</Button>
          ) : (
            ""
          )}
{matchingElements.map(function (value: any) {
            return <Chip label={value} color="error" />
          })}
          {/* {row.alert_words ? (
            <Chip label={row.alert_words} color="error" />
          ) : (
            ""
          )} */}
        </div>
  );
};

  const columns = [
    columnHelper.accessor("id", {
      cell: (info) => SelectorCell(info.row.original),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: () => <span>画像</span>,
      cell: (info) => ImageCell(info.row.original),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>商品名</span>,
      cell: (info) => TableCell(info.row.original),
      footer: (info) => info.column.id,
    }),

    //  columnHelper.accessor(row => row.name, {
    //    id: 'name',
    //   header: () => <span>商品名</span>,
    //   cell: info => <><TextField id="standard-basic" onChange={(e)=>change_title(e,info.row.original)} style={{width:700 ,background:(info.row.original.name_us && info.row.original.name_us.length>80)?"pink":""}} label={"タイトル   文字数"+(info.row.original.name_us?info.row.original.name_us.length:"")} error={info.row.original.name_us?info.row.original.name_us.length>80:false} variant="standard"  value={info.row.original.name_us}/><br/><TextField id="standard-basic" style={{width:700}} label="タイトル" variant="standard" onChange={()=>link(info.renderValue())} value={info.renderValue()}/>
    //   <br/>{info.row.original.images?info.row.original.images.split(',').map(function(value,key){
    //     if(key>0){
    //     return <img src={"https://frogger-images.s3.ap-northeast-1.amazonaws.com/"+info.row.original.id+"/"+info.row.original.id+"-"+(key+1)+".jpg"}
    //     height="100"
    //     style={{margin:5}}
    //     alt=""/>
    //     }
    //   }):""}</>,
    //   footer: info => info.column.id,
    // }),
    // {setImages(info.row.original)}
    // columnHelper.accessor(row => row.url, {
    //   id: 'url',
    //   cell: info =><Button variant="contained" onClick={() => link(info.renderValue())}>Link</Button>,
    //   header: () => <span><button>aaa</button></span>,
    //   footer: info => info.column.id,
    // }),

    columnHelper.accessor((row) => row.name, {
      id: "price",
      header: () => <span>金額/ブランド</span>,
      cell: (info) => TableBrand(info.row.original),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "shipping",
      header: () => <span>コスト/その他</span>,
      cell: (info) => TablePrice(info.row.original),
      footer: (info) => info.column.id,
    }),
    // columnHelper.accessor('visits', {
    //   header: () => <span>Visits</span>,
    //   footer: info => info.column.id,
    // }),
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    // columnHelper.accessor("status", {
    //   header: "Status",
    //   footer: (info) => info.column.id,
    // }),
    columnHelper.accessor("checked_at", {
      header: "checked_at",
      cell: (info) => TableCheck(info.row.original),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("discription", {
      header: "discription",
      cell: (info) => TextArea(info.row.original),
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    autoResetPageIndex: false,
    columnResizeMode,

    initialState: {
      pagination: {
        pageSize: 100,
        // pageIndex:page_index,
      },
    },
  });

  return (
    <div>
      <main>
        <Button onClick={() => save()}>保存</Button>
        <Button onClick={() => props.setStatus(0)}>戻る</Button>
        {over_text_flg ? (
          <Button onClick={() => all_text()}>
            すべて 文字超過
            {data.filter((a) => a.name_us && a.name_us.length > 80).length}
          </Button>
        ) : (
          <Button onClick={() => over_text()}>
            文字超過 文字超過
            {data.filter((a) => a.name_us && a.name_us.length > 80).length}
          </Button>
        )}
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="0"
              checked={checked == false}
              onClick={handleChecked}
              control={<Radio />}
              label="未チェック"
            />
            <FormControlLabel
              value="1"
              checked={checked == true}
              onClick={handleChecked}
              control={<Radio />}
              label="チェック済"
            />
          </RadioGroup>
        </FormControl>
        {/* <Button onClick={() => allchecked(true)} autoFocus>
          すべてチェック済みにする
        </Button>
        <Button onClick={() => allchecked(false)} autoFocus>
          すべてチェックを外す
        </Button> */}
        <Button onClick={() => pagechecked(true)} autoFocus>
          このページをチェック済みにする
        </Button>
        <Button onClick={() => pagechecked(false)} autoFocus>
          このページのチェックを外す
        </Button>
        {count}件
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  style={{
                    background: row.original.deleted_at ? "gray" : "",
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} onClick={() => console.log(cell)}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "5px" }}>
          <Button onClick={() => save()}>保存</Button>
          <Button onClick={() => props.setStatus(0)}>戻る</Button>
          <Button onClick={() => pagechecked(true)} autoFocus>
            このページをチェック済みにする
          </Button>
          <Button onClick={() => pagechecked(false)} autoFocus>
            このページのチェックを外す
          </Button>
          <span>Page</span>

          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </div>
        <div>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Age</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={table.getState().pagination.pageSize}
            label="Age"
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 100, 50].map((pageSize) => (
              <MenuItem value={pageSize}>{pageSize}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div>{table.getRowModel().rows.length} Rows</div>
      </main>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <img src={image_url} alt="" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={back_drop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
