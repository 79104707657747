// import Home from './components/Home';
// import SignUp from './components/SignUp';
// import Login from './components/Login';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { onAuthStateChanged, signOut } from "firebase/auth";
// import { auth } from "../firebaseAuth";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Register from './components/Register';
// import Login from './components/Login';
// import Mypage from './components/Mypage';
// import {
//   useNavigate,
//   // Navigate
// } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';




/* 「signOut」をimport↓ */
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebaseAuth";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




const drawerWidth = 240;
const Layout = (props) => {
  const [open, setOpen] = useState("");
  // const [token, setToken] = useState("");
  const [user, setUser] = useState("");

  const theme = useTheme();
  // const [token, setToken] = useState("");
  // const [data, setData] = useState([]);
  // const [token, setToken] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // rest(currentUser)
        setUser(currentUser).then(async function () {
          // let token = await user.getIdToken()
          // setToken(token)
        });

      } else {
        // navigate("/login/");
      }
      // setToken(currentUser.getIdToken);
      // console.log(currentUser.getIdToken)
    });




  }, []);




  /* ↓「navigate」を定義 */
  // const navigate = useNavigate();
  // useEffect(() => {
  //   onAuthStateChanged(auth, (currentUser) => {
  //     if (currentUser) {
  //       setUser(currentUser).then({

  //       });

  //     } else {
  //       navigate("/login/");
  //     }
  //     // setToken(currentUser.getIdToken);
  //     // console.log(currentUser.getIdToken)
  //   });

  // const navigate = useNavigate();

  /* ↓関数「logout」を定義 */
  const logout = async () => {
    await signOut(auth);
    // navigate("/login/");
  }



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  // const research = async () => {

  //   navigate("/research/");
  // }
  // }, []);

  // /* ↓「navigate」を定義 */
  // 

  // /* ↓関数「logout」を定義 */
  // const logout = async () => {
  //   await signOut(auth);
  //   navigate("/login/");
  // }
  return (


    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}
      // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Froggerss
          </Typography>
          {user.email}
          <button onClick={logout}>ログアウト</button>
        </Toolbar>

      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem key={"scrapinglistings"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/scrapinglistings"><ListItemText primary={"出品チェック"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>
            
            
            {/* <ListItem key={"scrapinglistings"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/scrapinglistings">   <ListItemText primary={"出品チェック"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem> */}



            <ListItem key={"orderlistings"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/orderlistings">   <ListItemText primary={"注文・オファー"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>


            <ListItem key={"messages"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/Messages">   <ListItemText primary={"メッセージ"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>


         


            <ListItem key={"waitlistings"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/waitlistings">   <ListItemText primary={"出品待機"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>
            <ListItem key={"Calculations"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/Calculations">   <ListItemText primary={"利益計算"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>

            <ListItem key={"自動ログ"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                  <Link to="/AutomationLogs">   <ListItemText primary={"自動ログ"} /></Link>
                </ListItemIcon>

              </ListItemButton>
            </ListItem>
            
          </List>
          <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {/* <p>{user?.email}</p> */}
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box >
      </Drawer >
      <Main open={open}>
        <DrawerHeader />
        {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
        <Toolbar />
        {props.children}
        {/* </Box> */}
      </Main>
    </Box >

    // <div className="container">

    // </div>
  );
};

export default Layout;