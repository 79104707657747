
import React, { useState, useEffect } from "react";
/* 「signOut」をimport↓ */
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseAuth";
import DataGridResearch from "./DataGridResearch";


/* ↓「useNavigate」をimport */

import {
    useNavigate,
    // Navigate
} from "react-router-dom";

const Research = () => {
    const [user, setUser] = useState("");
    // const [token, setToken] = useState("");
    // const [data, setData] = useState([]);
    // const [token, setToken] = useState("");

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // rest(currentUser)
                setUser(currentUser).then(async function () {
                    // let token = await user.getIdToken()
                    // setToken(token)
                });

            } else {
                navigate("/login/");
            }
            // setToken(currentUser.getIdToken);
            // console.log(currentUser.getIdToken)
        });




    }, []);




    /* ↓「navigate」を定義 */
    const navigate = useNavigate();



    return (
        <>  <b>リサーチ</b>
            {/* ↓ログインしていない場合はログインページにリダイレクトする設定 */}
            {!user ? (
                ""
            ) : (
                <>aaaa
                    <b>リサーチ</b>

                    <DataGridResearch user={user} />
                    {/* <p> {token}</p> */}

                    {/* <button onClick={rest}>rest</button> */}
                </>
            )}
        </>

    );
};

export default Research;