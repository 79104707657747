import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridEventListener, GridColumnHeaderParams } from '@mui/x-data-grid';
import { format } from 'date-fns'
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat } from 'react-number-format';

import DataGridLink from "./DataGridLink";


const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: 'right' }}

      onValueChange={values => {

        onChange({

          target: {
            value: parseInt(values.value),
          }
        });
      }}
      thousandSeparator

    />
  );
}

const DataGridStock = (props: any) => {
  // const [open, setOpen] = React.useState(false);
  // const [id, setId] = React.useState(false);

  const [researches, setResearches] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [item_research_id, set_item_research_id] = React.useState(0);


  useEffect(() => {


    rest()

  }, []);





  const rest = async () => {
    // alert(2)
    let token = await props.user.getIdToken()
    // setToken(token)
    alert(token)
    // console.log(token)
    // alert("https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/researches")
alert("https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/items/researches/1")

    const { data } = await axios.get('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/researches/1', {
      headers: {
        Authorization: `Bearer ${token}`,

      }
    })
    // https://k0x5azctpi.execute-api.ap-northeast-3.amazonaws.com/default/
    // alert(data.body.length)
    alert(data.length)
    setResearches(data)
    // alert(Json.stringfy(data))
    console.log(data)
  }

  const save = async () => {
    // alert()
    let token = await props.user.getIdToken()
    // alert(token)
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    console.log('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/stocks/save')
    const res = await axios.post('https://29brdtwni2.execute-api.ap-northeast-1.amazonaws.com/frogger/researches/1', [], { headers: headers })




    console.log(JSON.stringify(res.data))
    props.rest()

    setOpen(false);
    // https://k0x5azctpi.execute-api.ap-northeast-3.amazonaws.com/default/
    // alert(data.length)
    // setStockData(data)
    console.log(res.data)
  }


  const detail_open = (id: number) => {
    // window.open(url)
    set_item_research_id(id)
    setOpen(true);

  }
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '検索項目',
      width: 300,
      editable: true,
      // renderCell: (params: GridRenderCellParams) => (
      //   format(new Date(params.row.purchase_at), 'yyyy/MM/dd')
      // )
    },

    {
      field: 'url',
      headerName: '詳細',
      width: 100,
      editable: true,
      headerAlign: 'center',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (

        <Button onClick={() => detail_open(params.row.id)} variant="contained" size="small"> 詳細</Button>
      ), renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Birthday '}
          <span role="img" aria-label="enjoy">
            🎂
          </span>
        </strong>
      ),
    },
    // {
    //   field: 'tax',
    //   headerName: '税額',
    //   width: 100,
    //   editable: true,
    //   headerAlign: 'center',
    //   align: 'right',
    //   // renderCell: (params: GridRenderCellParams) => (
    //   //   params.row.tax.toLocaleString()
    //   //   //   < TextField id="standard-basic" variant="standard" value={params.row.name} />
    //   // )
    // },
    // {
    //   field: 'delivery_cost',
    //   headerName: '配送料',
    //   width: 100,
    //   editable: true,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: (params: GridRenderCellParams) => (
    //     params.row.delivery_cost.toLocaleString()
    //     //   < TextField id="standard-basic" variant="standard" value={params.row.name} />
    //   )
    // },
    // {
    //   field: 'sum',
    //   headerName: '合計',
    //   width: 100,
    //   editable: true,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: (params: GridRenderCellParams) => (
    //     (Number(params.row.cost ? params.row.cost : 0) + Number(params.row.tax ? params.row.tax : 0) + Number(params.row.delivery_cost ? params.row.delivery_cost : 0)).toLocaleString()
    //   )
    // }

  ];


  const handleChange = (e: any, koumoku: any) => {

    // let array = JSON.parse(JSON.stringify(stock_details)) // 複製
    let value = e.target.value


    // array[koumoku] = value

    // setStockDetails(array);
    // console.log(stock_details)
  };



  const handleClose = () => {
    setOpen(false);
  };
  const Create = (
  ) => {
    setOpen(true);
    // let array = JSON.parse(JSON.stringify(stock_details)) // 複製



    // array.stock_id = 0

    // setStockDetails(array);
    // setOpen(true);

    // alert(params.row.id)
    // rest()
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {

    // setStockDetails({ stock_id: params.row.id, item_id: params.row.item_id, purchase_at: format(new Date(params.row.purchase_at), 'yyyy-MM-dd'), cost: params.row.cost, tax: params.row.tax, delivery_cost: params.row.delivery_cost })
    // setOpen(true);

    // rest()
  };
  console.log(props.data)

  return (
    <div style={{ height: window.innerHeight - 200, width: '100%' }}>
      <Button onClick={Create}>新規登録</Button>
      <DataGrid
        rows={researches}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        disableSelectionOnClick
      // onRowClick={handleEvent}
      />


      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <TextField
              id="purchase_at"
              label="仕入日"
              type="date"
              // defaultValue={stock_details.purchase_at}
              sx={{ m: 1, width: 250 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange(e, 'purchase_at')}
            /> */}


            <TextField

              sx={{ m: 1, width: "100%" }}
              id="cost"
              label="検索項目名"
              name="cost"
              // value={stock_details.cost}
              // type="number"
              // className={classes.textField}
              // InputProps={{
              //   inputComponent: NumberFormatCustom, style: { textAlign: 'right' },
              //   endAdornment: <InputAdornment position="end">円</InputAdornment>,
              // }}

              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              onChange={(e) => handleChange(e, 'cost')}
            />

            {/* <TextField

              sx={{ m: 1, width: "100%" }}
              id="cost"
              label="検索URL"
              name="cost"
              // value={stock_details.cost}
              // type="number"
              // className={classes.textField}
              // InputProps={{
              //   inputComponent: NumberFormatCustom, style: { textAlign: 'right' },
              //   endAdornment: <InputAdornment position="end">円</InputAdornment>,
              // }}

              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              onChange={(e) => handleChange(e, 'cost')}
            /> */}

            <DataGridLink user={props.user} item_research_id={item_research_id} />
            {/* <TextField
              sx={{ m: 1, width: 250 }}
              id="tax"
              label="税"
              name="tax"
              // value={stock_details.tax}
              // type="number"
              // className={classes.textField}
              InputProps={{
                inputComponent: NumberFormatCustom, style: { textAlign: 'right' },
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}

              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              onChange={(e) => handleChange(e, 'tax')}
            />
            <TextField
              sx={{ m: 1, width: 250 }}
              id="delivery_cost"
              label="配送費"
              name="delivery_cost"
              // value={stock_details.delivery_cost}
              // type="number"
              // className={classes.textField}
              InputProps={{
                inputComponent: NumberFormatCustom, style: { textAlign: 'right' },
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}

              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              onChange={(e) => handleChange(e, 'delivery_cost')}
            />

            <TextField
              disabled
              sx={{ m: 1, width: 250 }}
              id="sum"
              label="合計額"
              variant="standard"
              name="sum"
              // value={Number(stock_details.cost ? stock_details.cost : 0) + Number(stock_details.tax ? stock_details.tax : 0) + Number(stock_details.delivery_cost ? stock_details.delivery_cost : 0)}
              // type="number"
              // className={classes.textField}
              InputProps={{
                inputComponent: NumberFormatCustom, style: { textAlign: 'right' },
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}

              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            // onChange={this.handleChange_application_fee}
            /> */}


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={save}>登録</Button>
          <Button onClick={handleClose} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default DataGridStock

